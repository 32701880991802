<template>
  <div class="build-view" :class="showPassageModal ? 'build-view__hide-scroll' : ''">
    <standards-dialog v-if="loadStannis" :show="showStannis" customActivator @stannis-hide="showStannis = false" />

    <ibx-modal :show="showPassageModal" height="95vh" width="90vw" @close="onHidePassage">
      <ibx-passage-card v-if="showPassageModal" v-bind="passageModalData" @close="onHidePassage" />
    </ibx-modal>

    <!-- action bar -->
    <ibx-action-bar :active="actionBar">
      <div class="build-view-action-bar">
        <div class="build-view-action-bar__message">
          {{ actionBarMessage }}
        </div>
        <div class="build-view-action-bar__actions">
          <x-btn color="danger" @click="onRemoveSelected">Remove</x-btn>
        </div>
      </div>
    </ibx-action-bar>

    <div class="ibx-app-content">
      <!-- questions -->
      <div class="ibx-app-content__content ibx-app-content__content--build">
        <build-asmt-message v-if="showAsmtErrors" />
        <ibx-items-toolbar class="build-items-toolbar" context="mode-build">
          <span slot="label" class="build-items-toolbar__left">
            <v-tooltip
              color="#050F2D"
              bottom
              nudge-bottom="-15px"
              open-delay="0"
              close-delay="0"
              content-class="custom-tooltip"
              transition="none"
            >
              <template v-slot:activator="{ on: tooltip }">
                <v-checkbox
                  v-show="canListItems && !permIsPublished && !permIsViewOnly"
                  v-on="{ ...tooltip }"
                  class="checkbox"
                  :value="allSelected"
                  :indeterminate="hasSomeItemsSelected"
                  @change="onSelectAllItems"
                  color="primary lighten-1"
                />
              </template>
              <span v-if="allSelected">Unselect all</span>
              <span v-else>Select all</span>
            </v-tooltip>
            <span class="label">
              <strong>{{ asmtItemCount }}</strong> Total Items
            </span>
          </span>

          <span
            slot="actions"
            class="mr-3"
            style="cursor: pointer; font-size: 16px"
            v-if="this.featureFlag(FLAG.PRINT_BOOKLETS)"
          >
            <v-tooltip
              color="#050F2D"
              bottom
              open-delay="0"
              close-delay="0"
              content-class="custom-tooltip"
              transition="none"
            >
              <template v-slot:activator="{ on, attrs }">
                <font-awesome-icon
                  :icon="['fas', 'print']"
                  v-bind="attrs"
                  v-on="on"
                  @click="() => onShowPrintDialog(true)"
                />
              </template>
              <span>Print preview</span>
            </v-tooltip>
          </span>
        </ibx-items-toolbar>

        <!-- items list -->
        <div class="browse-items-list" v-bind="$attrs" v-on="$listeners" v-if="canListItems && !loading">
          <draggable
            element="div"
            v-model="itemsSortable"
            v-bind="dragOptions"
            @start="drag = true"
            @end="drag = false"
          >
            <transition-group
              type="transition"
              :name="!drag ? 'flip-list' : null"
              class="build-item-transition-wrapper"
            >
              <build-item
                v-for="o in itemsSortable"
                :key="`${getCardId(o)}`"
                v-bind="getCardAttrs(o)"
                :selected="isItemSelected(o.itemRevId)"
                :view="buildItemsListView"
                dragHandle="drag-handle"
                context="build"
                lazy
                :lazyLoadTrigger="lazyLoadTriggers[getCardId(o)]"
                :id="getCardId(o)"
                ref="cards"
                @item-selected="onItemSelected"
                @assessment-item-action="onAction"
                @stannis-show="onShowStannis"
                @view-passage="onViewPassage"
                @loaded="() => onItemLoaded({ id: getCardId(o) })"
                @load-error="() => onItemLoaded({ id: getCardId(o) })"
              />
            </transition-group>
          </draggable>
        </div>

        <div class="build-view__no-items" v-if="!canListItems && !loading">
          <img src="assets/img/piggy-bank.png" alt="No Items Added" />
          <div class="text-wrapper">
            <h1>No Items Added</h1>
            <p>
              Looks like you don’t have any items added to your assessment yet. Get started by clicking
              <strong>Browse</strong>, or create your own items by clicking
              <strong>Create Item.</strong>
            </p>
          </div>
        </div>

        <div class="build-view__loading" v-if="loading">Loading Items</div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { EventBus, Events } from '@events'
import IbxActionBar from '@components/ibx/IbxActionBar'
import BuildItem from '@components/build/BuildItem'
import { AppMixin, AsmtMixin, SavingMessageMixin, PermsMixin, ItemListMixin } from '@mixins'
import XBtn from '@xLib/XBtn'
import IbxItemsToolbar from '@components/ibx/_IbxItemsToolbar'
import * as UTIL from '@helpers'
import * as VTextField from 'vuetify/es5/components/VTextField'
import * as VCard from 'vuetify/es5/components/VCard'
import * as VCheckbox from 'vuetify/es5/components/VCheckbox'
import draggable from 'vuedraggable'
import CONST from '@constants'
import _ from 'lodash'
import * as VTooltip from 'vuetify/es5/components/VTooltip'
import { FLAG } from '@/constants'

export default {
  name: 'BuildView',

  mixins: [AppMixin, AsmtMixin, SavingMessageMixin, PermsMixin, ItemListMixin],

  components: {
    ...VCard,
    ...VTextField,
    ...VCheckbox,
    ...VTooltip,
    IbxActionBar,
    BuildItem,
    draggable,
    XBtn,
    IbxItemsToolbar,
    'ibx-passage-card': () => import('@components/ibx/_IbxPassageCard'),
    'ibx-modal': () => import('@components/ibx/IbxModal'),
    'standards-dialog': () => import('@components/standards/StandardsDialog'),
    'build-asmt-message': () => import('@components/build/BuildAsmtMessage'),
  },

  data: () => ({
    itemsSelected: [],
    view: 'collapse',
    showStannis: false,
    loadStannis: false,
    showPassageModal: false,
    passageModalData: {},
    drag: false,
    loading: true,
    scrollY: 0,
  }),

  computed: {
    ...mapGetters('features', {
      featureFlag: 'flag',
    }),
    ...mapGetters('ui', ['buildItemsListView']),
    ...mapGetters('asmt', {
      asmtDirty: 'dirty',
      asmtHasErrors: 'hasErrors',
      asmtPublishedAt: 'publishedAt',
    }),
    showAsmtErrors() {
      return !this.asmtPublishedAt && this.asmtHasErrors
    },
    sortedGroupedItems() {
      const sortedItems = _.sortBy(this.asmtItems, [(o) => o.order])
      const groupedData = sortedItems.reduce((m, o, i) => {
        const hasPassage = Boolean(o.item.passage)

        // group items for passage
        if (hasPassage) {
          let passage = m.find((mo) => mo.passageId == o.item.passage.passageId)

          if (!passage) {
            // create passage object & set order to first item order value
            passage = {
              ...o.item.passage,
              order: o.order,
              items: [],
            }
            m.push(passage)
          }

          // add item to passage items list
          passage.items.push(o)
        } else {
          // item with no passage
          m.push(o)
        }

        return m
      }, [])

      return groupedData
    },
    itemsSortable: {
      get() {
        return this.sortedGroupedItems
      },
      set(sortedItems) {
        // TODO: disable editing while this is processing (with EventBus)
        const itemsFlat = sortedItems.reduce((m, o, index) => {
          let order = m.length - 1
          if (o.passageRevId) {
            o.items.forEach((item, i) => {
              order = m.length - 1
              m.push({
                itemRevId: item.itemRevId,
                order: order + 1,
              })
            })
          } else {
            m.push({
              itemRevId: o.itemRevId,
              order: order + 1,
            })
          }
          return m
        }, [])

        EventBus.$emit(Events.ASSESSMENT_ITEMS_ORDER, {
          sorted: itemsFlat,
        })
      },
    },
    canListItems() {
      return this.asmtHasItems
    },
    dragOptions() {
      return {
        animation: 300,
        disabled: this.permIsPublished || this.permIsViewOnly,
        ghostClass: 'ghost',
        handle: '.drag-handle',
        group: 'asmt-item',
      }
    },
    actionBar() {
      if (this.itemsSelected.length > 0) return true
      else return false
    },
    actionBarMessage() {
      return `${this.itemsSelected.length} items selected`
    },
    allSelected() {
      return this.itemsSelected.length > 0 && this.itemsSelected.length == this.asmtItems.length
    },
    hasSomeItemsSelected() {
      return this.itemsSelected.length > 0 && this.itemsSelected.length != this.asmtItems.length
    },
    getPluralItemsSelected() {
      return this.itemsSelected.length == 1 ? '' : 's'
    },
    isShowDescription() {
      return this.$route.query.description === '1'
    },
    isShowTags() {
      return this.$route.query.tags === '1'
    },
    assessmentOrder() {
      return _.sortBy(this.asmtItems, [(o) => o.order]).map(({ itemRevId, order }) => ({ itemRevId, order }))
    },
    FLAG() {
      return FLAG
    },
  },

  watch: {
    asmtItems(items) {
      this.hasItems = items.length > 0
    },
    loading(v) {
      this.asmtSetLoading(v)
    },
  },

  methods: {
    ...mapActions('ui', ['setBuildItemsListView']),
    ...mapActions('browseItems', {
      browseItemsSetStale: 'setStale',
    }),
    getCardAttrs(data) {
      if (data.passageRevId) {
        return {
          passage: data,
          itemsSelected: this.itemsSelected,
        }
      } else {
        return {
          itemRevId: data.itemRevId,
          itemId: data.itemsId,
          asmtItem: data,
          preview: true,
          mode: 'view',
          role: 'instructor',
        }
      }
    },
    onItemSelected({ itemRevId, selected }) {
      if (selected) this.itemsSelected = _.uniq([...this.itemsSelected, itemRevId])
      else this.itemsSelected = this.itemsSelected.filter((_itemRevId) => _itemRevId !== itemRevId)
    },
    onSelectAllItems(selected) {
      if (selected) this.itemsSelected = this.asmtItems.map((o) => o.itemRevId)
      else this.itemsSelected = []
    },
    clearSelected() {
      this.itemsSelected = []
    },
    onAction({ actionId, itemRevId, data }) {
      switch (actionId) {
        case Events.REMOVE_ITEMS:
          this.onRemoveItems({ itemRevIds: [itemRevId] })
          break
      }
    },
    onRemoveSelected() {
      const count = this.itemsSelected.length
      this.smSaving()
      this.asmtRemoveItems(this.itemsSelected)
        .then(() => {
          this.smSaved()
          this.clearSelected()
          EventBus.$emit(Events.REMOVED_ITEMS, {
            added: false,
            count,
          })
        })
        .catch((error) => {
          this.smDone()
          EventBus.$emit(Events.ERROR, {
            type: CONST.ERROR,
            error,
            text: 'Failed to remove selected item' + this.getPluralItemsSelected,
            subtext: error?.subtext ? error.subtext : CONST.REFRESH_PAGE,
          })
        })
    },
    onRemoveItems({ itemRevIds = [] }) {
      this.smSaving()
      this.asmtRemoveItems(itemRevIds)
        .then(() => {
          this.smSaved()
          this.clearSelected()
          EventBus.$emit(Events.REMOVED_ITEMS, {
            added: false,
            count: itemRevIds.length,
          })
        })
        .catch((error) => {
          this.smDone()
          EventBus.$emit(Events.ERROR, {
            type: CONST.ERROR,
            error,
            text: 'Failed to remove item' + this.getPluralItemsSelected,
            subtext: error?.subtext ? error.subtext : CONST.REFRESH_PAGE,
          })
        })
    },
    isItemSelected(id) {
      return this.itemsSelected.includes(id)
    },
    onViewPassage({ passage }) {
      this.scrollY = window.scrollY
      this.passageModalData = {
        passage: passage,
        passageId: passage.passageId,
        passageITems: passage.items,
        view: 'expand',
        stickyHeader: true,
        noFooter: true,
        heightAuto: true,
        noAnimation: true,
        close: true,
        actions: [],
      }
      this.showPassageModal = true
    },
    onHidePassage() {
      this.showPassageModal = false
      this.$nextTick(() => {
        window.scrollTo({
          top: this.scrollY,
        })
      })
    },
    onShowPrintDialog(show) {
      EventBus.$emit(Events.ASSESSMENT_PRINT_PREVIEW, show)
    },
    async addToAssessment(itemRevIds = []) {
      return new Promise((resolve, reject) => {
        if (itemRevIds.length) {
          this.browseItemsSetStale(true)
          this.asmtAddItems(itemRevIds)
            .then(() => this.asmtSaveItemsToAdd())
            .catch((error) => {
              if (error != Events.ASSESSMENT_ITEM_LIMIT) {
                EventBus.$emit(Events.ERROR, {
                  type: CONST.ERROR,
                  error,
                  text: 'Failed to add item',
                  subtext: error?.subtext ? error.subtext : CONST.REFRESH_PAGE,
                })
              }
            })
            .finally(() => resolve())
        } else {
          return resolve()
        }
      })
    },

    /**
     * Update question weights.
     * @param items itemsRevIds to update
     */
    async updateRubricWeights(items = []) {
      return new Promise((resolve, reject) => {
        if (items.length) {
          // calculate and set weights in store
          const promises = items.map(async (itemRevId) => this.asmtSetRubricItemWeight({ itemRevId }))
          resolve()
          Promise.all(promises)
            .then(() =>
              this.asmtItems
                .filter((o) => items.includes(o.itemRevId))
                .map((o) => ({ itemRevId: o.itemRevId, weight: o.weight }))
            )
            .then((itemsToUpdate) => this.asmtUpateItems(itemsToUpdate))
            .finally(() => resolve())
        } else {
          return resolve()
        }
      })
    },

    /**
     * Item Authoring editing/creating done handler
     * @param {{ authorMode, itemsMutated: ItemsMutated[], action }} data
     */
    async onEditItemsDone({ authorMode, itemsMutated = [], action = null }) {
      // filter out deleted items, set to [] publishing-new action taken
      const items = action != CONST.PUBLISH_NEW ? itemsMutated.filter((o) => !o.actions.includes('delete')) : []

      if (this.asmtId && items && items.length) {
        const itemsToAdd = authorMode == 'create' ? _.uniq(items.map((o) => o.itemRevId)) : []

        try {
          this.loading = true
          await this.addToAssessment(itemsToAdd)
          await this.asmtFetchAssessmentItems(false)

          // update question weights for rubrics if needed
          const rubricItemsToUpdate = items
            .filter((o) => this.asmtItemSupportsRubric(o.itemRevId))
            .map((o) => o.itemRevId)
          await this.updateRubricWeights(rubricItemsToUpdate)

          this.loading = false
          const resetTriggers = itemsToAdd.length
          this.$nextTick(() => {
            if (this.resetTriggers) this.resetQueue(this.sortedGroupedItems)
            this.checkItemsInView()
          })
        } catch (error) {
          this.onError(error)
        }
      }
    },

    onShowStannis() {
      this.loadStannis = true
      this.$nextTick(() => (this.showStannis = true))
    },
    onError(error) {
      if (error.response && (error.response.status == 404 || error.response.status == 403)) {
        EventBus.$emit(Events.STATUS_404)
      } else {
        EventBus.$emit(Events.ERROR, { type: CONST.ERROR, error })
      }
    },
    onAppInit() {
      const assessmentId = this.$route.params.assessmentId
      if (assessmentId) {
        this.amstFetchAssessment({ assessmentId })
          .then(() => {
            if (this.asmtItemsToAddCount > 0) {
              return this.asmtSaveItemsToAdd()
            }
          })
          .then(() => this.asmtFetchAssessmentItems())
          .then(() => {
            this.viewThreshold = 2000
            this.initItemListQueue({
              context: this.context,
              items: this.sortedGroupedItems,
              max: 12,
              debounce: 0,
            })
            this.loading = false
            if (this.permIsPublished || this.permIsViewOnly) this.setBuildItemsListView(CONST.EXPAND)
            else this.setBuildItemsListView(CONST.COLLAPSE)
            setTimeout(() => this.checkItemsInView())
          })
          .then(() => {
            if (this.isShowDescription) {
              EventBus.$emit(Events.SHOW_DESCRIPTION, true)
            }
            if (this.isShowTags) {
              EventBus.$emit(Events.SHOW_TAGS, true)
            }
          })
          .then(() => {
            const isPrintPreviewURL = this.$route.query.printPreview?.toLowerCase() === 'true'
            this.onShowPrintDialog(isPrintPreviewURL)
          })
          .catch(this.onError)
      }
    },
  },

  beforeDestroy() {
    EventBus.$off(Events.EDIT_ITEMS_DONE, this.onEditItemsDone)
  },

  mounted() {
    this.appInit({ context: CONST.MODE_BUILD })
    EventBus.$on(Events.EDIT_ITEMS_DONE, this.onEditItemsDone)
  },
}
</script>

<style lang="scss">
@import '@/styles/main';

.build-view {
  .ibx-app-content {
    margin-top: 94px;
    max-width: 1400px;

    .build-asmt-message {
      margin: 0 0 20px 44px;
    }
  }

  &__no-items {
    width: 400px;
    margin: 64px auto 0 auto !important;
    text-align: center;
    font-family: 'CerebriSans', Roboto, sans-serif;

    img {
      height: 200px;
    }

    .text-wrapper {
      width: 280px;
      margin: 0 auto;
    }
  }
}

.build-items-toolbar {
  margin-left: 44px;
  margin-bottom: 20px;

  &__left {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    position: relative;

    i.v-icon.material-icons.theme--light.primary--text.text--lighten-1 {
      color: #008272 !important;
    }
    .checkbox {
      position: absolute;
      left: -54px;
      top: -8px;

      * {
        font-size: 24px !important;
      }

      .v-input__control {
        flex-direction: row;
      }
      .v-input__slot {
        justify-content: center !important;
      }
    }

    .v-input.checkbox.v-input--is-dirty {
      .v-icon {
        color: #82b1ff !important;
      }
    }
  }
}

.build-view {
  .ghost {
    background: none;
    background-color: none;

    .build-item-card {
      border: none !important;
    }

    .build-item__selector {
      opacity: 0;
    }
  }

  .sortable-chosen {
    background: none;
    background-color: none;

    .build-item-card {
      border: 2px solid $B300;
    }
  }
  .sortable-ghost {
    //...
  }

  .sortable-drag {
    background: none;
    background-color: none;

    .build-item__selector {
      opacity: 0;
    }
  }

  .sortable-fallback {
    visibility: hidden;
  }

  &__no-items,
  &__loading {
    width: 400px;
    margin: 0 auto;
    text-align: center;
    font-family: 'CerebriSans', Roboto, sans-serif;

    .text-wrapper {
      max-width: 290px;
      margin: 0 auto;
    }
  }

  &__hide-scroll {
    overflow: hidden;
    height: calc(100vh - 200px);
  }
}

.build-view-action-bar {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 44px;

  &__message {
    margin-right: 40px;
  }

  &__actions {
    //...
  }
}

/* ------------------------------
 * Header
 * ------------------------------ */
.build-view-header {
  display: block;
  height: 100px;
  padding-left: 44px;

  .build-view-header-container {
    display: flex;
    background: grey;
    width: 100%;

    &__col {
      flex: 1 1 auto;
      border: 1px solid red;
      background: pink;

      &--1 {
        background: greenyellow;
        flex: 0 0 50px;
      }

      &--2 {
        background: greenyellow;
        flex: 0 0 auto;

        .content {
          width: 200px;
          background: pink;
        }
      }

      &--3 {
        background: greenyellow;
      }

      &--4 {
        background: greenyellow;
      }

      &--5 {
        background: greenyellow;
      }
    }
  }
}
@media print {
  .build-items-toolbar {
    display: none;
  }
}
</style>
